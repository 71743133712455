export const environment = {
  env: 'township',
  name: 'Lokale Bon',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCBi6Dvcx73fZwU5HnMn2jUarL4viX05L8',
    authDomain: 'wemaron-vouchers-dev.firebaseapp.com',
    databaseURL: 'https://wemaron-vouchers-dev.firebaseio.com',
    projectId: 'wemaron-vouchers-dev',
    storageBucket: 'wemaron-vouchers-dev.appspot.com',
    messagingSenderId: '804844036454',
    appId: '1:804844036454:web:b365c1a59fba197dca28a7',
    measurementId: 'G-JQ5FFXX8MT',
  },
  functionsUrl: 'https://us-central1-wemaron-vouchers-dev.cloudfunctions.net',
  prefix: 'lokale',
  prefixImg:
    'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers-dev.appspot.com/o/ImgEmail%2Flokale_bon.jpeg?alt=media&token=2e7320e0-be73-4d08-be23-73f56c076ae1',
  loginMethod: {
    phone: false,
    email: true,
  },
  login: {
    image:
      'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/banner.png?alt=media&token=c9bbade3-2dc5-4ed6-98fb-9f5248915d46',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  defaultRoute: {
    id: 'dashboardtownship',
    path: 'dashboard-township',
  },
  routes: [
    'login',
    'registration',
    'dashboardtownship',
    'settings',
    'vouchermanagement',
    'organisationmanagement',
    'tags',
    'payment',
  ],
};
